<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <main class="main">
    <div class="container">
      <BreadcrumbsComponent :title="pageData ? pageData.title : 'Каталог'" />
      <section class="ctl">
        <aside class="ctl__aside">
          <form class="ctl__aside__item" @submit.prevent="getResults(page)">
            <label for="search" class="ctl__aside__label">Поиск</label>
            <div class="ctl__search">
              <input
                type="text"
                id="search"
                placeholder="Введите запрос"
                class="ctl__search__input"
                v-model="q"
              />
              <button type="button" class="ctl__search__btn">
                <ArrowIcon />
              </button>
            </div>
          </form>
          <div class="ctl__aside__item" v-if="vehicles.length">
            <label class="ctl__aside__label">Тип</label>
            <label
              class="ctl__type-input"
              :class="{ active: type.includes(vehicle.id) }"
              v-for="(vehicle, index) in vehicles"
              :key="index"
            >
              <i class="icon" v-html="vehicle.icon"></i>
              <input type="checkbox" class="ctl__type-input__field" v-model="type" :value="vehicle.id" />
              <span class="ctl__type-input__title">{{ vehicle.button_title || vehicle.title }}</span>
            </label>
          </div>
          <div class="ctl__aside__item" v-if="brands.length">
            <label class="ctl__aside__label">Марка</label>
            <perfect-scrollbar tag="div" class="ctl__models" :options="settings">
              <label
                class="ctl__models__label"
                :class="{ active: models.includes(brand.id) }"
                v-for="(brand, index) in brands"
                :key="index"
              >
                <input type="checkbox" class="ctl__models__input" v-model="models" :value="brand.id" />
                <span class="ctl__models__title">{{ brand.title }}</span>
              </label>
              <span class="ctl__models__gradient" v-if="brands.length > 2"></span>
            </perfect-scrollbar>
          </div>
          <div class="ctl__aside__actions">
            <button type="button" class="btn btn--small" @click="getResults(0)">
              <LoadingIndicator v-if="loading" title="Подождите" />
              <span v-else>Фильтр</span>
            </button>
            <button type="button" class="btn-white btn-white--small" @click="resetResults">
              <LoadingIndicator v-if="loading" title="Подождите" />
              <span v-else>Сбросить</span>
            </button>
          </div>
        </aside>
        <div class="ctl__body">
          <div class="ctl__active-filters" v-if="models.length || type.length">
            <span class="ctl__active-filters__title">Выбрано:</span>
            <ul class="ctl__active-filters__list">
              <template v-if="vehicles.length">
                <li v-for="(t, index) in type" :key="index">
                  <span>{{ vehicles.find((v) => v.id === t).title }}</span>
                  <button type="button" @click="type.splice(index, 1)">
                    <CloseIcon />
                  </button>
                </li>
              </template>
              <template v-if="brands.length">
                <li v-for="(model, index) in models" :key="index">
                  <span>{{ brands.find((b) => b.id === model).title }}</span>
                  <button type="button" @click="models.splice(index, 1)">
                    <CloseIcon />
                  </button>
                </li>
              </template>
            </ul>
          </div>
          <ul class="ctl__items" v-if="products.data.length">
            <li v-for="(product, index) in products.data" :key="index">
              <router-link
                :to="{ name: 'car', params: { brand: product.brand.link, url: product.link } }"
                class="car"
              >
                <h2 class="car__title">{{ product.title }}</h2>
                <div class="car__img__block">
                  <span
                    class="car__red-mark"
                    v-if="product.share"
                    :content="product.share.title"
                    v-tippy="{ followCursor: false, placement: 'top', theme: 'car__tooltip' }"
                  ></span>
                  <img
                    loading="lazy"
                    decoding="async"
                    :src="getImg(product)"
                    :alt="getImgAlt(product)"
                    class="car__img"
                  />
                </div>
                <div class="car__prices">
                  <div class="car__price__item" v-if="product.full_price || product.price">
                    <div class="car__price__col">
                      <span class="car__price" v-if="product.full_price">
                        от {{ product.full_price.toLocaleString("ru-RU") }} ₽
                      </span>
                      <span class="car__price" v-else-if="product.price">
                        {{ product.price.toLocaleString("ru-RU") }} ₽
                      </span>
                      <span class="car__price__type">цена</span>
                    </div>
                  </div>
                  <div class="car__price__item" v-if="product.price_per_month">
                    <div class="car__price__col">
                      <span class="car__price"
                        >от {{ product.price_per_month.toLocaleString("ru-RU") }} ₽</span
                      >
                      <span class="car__price__type">в месяц</span>
                    </div>
                  </div>
                  <div class="car__price__item car__price__item--bright" v-if="product.tax_savings_price">
                    <div class="car__price__check">
                      <SuccessIcon />
                    </div>
                    <div class="car__price__col">
                      <span class="car__price">
                        <span>от {{ product.tax_savings_price.toLocaleString("ru-RU") }} ₽</span>
                        <!--                        <QuestionIcon v-tooltip="{ content: 'Текст', classes: 'adv__tooltip', placement: 'top' }"/>-->
                      </span>
                      <span class="car__price__type">в месяц</span>
                    </div>
                  </div>
                </div>
                <button type="button" class="btn-dark">Открыть</button>
              </router-link>
            </li>
          </ul>
          <span class="ctl__active-filters__title" v-else>Информация скоро появится</span>
          <nav class="pgn" v-if="products.data && products.data.length && products.total > products.per_page">
            <ClientOnly>
              <Pagination :data="products" @pagination-change-page="getResults" :limit="1">
                <span class="pgn__btn no-mobile" slot="first-nav">
                  <PaginationDArrowLeftIcon />
                </span>
                <span class="pgn__btn" slot="prev-nav">
                  <PaginationArrowLeftIcon />
                </span>
                <template v-slot:page-nav="slotProps">
                  <span class="pgn__btn">{{ slotProps.page }}</span>
                </template>
                <template v-slot:current-nav="slotProps">
                  <span class="pgn__btn active">{{ slotProps.page }}</span>
                </template>
                <span class="pgn__btn" slot="next-nav">
                  <PaginationArrowRightIcon />
                </span>
                <span class="pgn__btn no-mobile" slot="last-nav">
                  <PaginationDArrowRightIcon />
                </span>
              </Pagination>
            </ClientOnly>
          </nav>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ArrowIcon from "components/svg/Arrow.vue";
import CloseIcon from "components/svg/Close.vue";
import SuccessIcon from "components/svg/Success.vue";
import QuestionIcon from "components/svg/Question.vue";
import PaginationDArrowLeftIcon from "components/svg/PaginationDArrowLeft.vue";
import PaginationArrowLeftIcon from "components/svg/PaginationArrowLeft.vue";
import PaginationDArrowRightIcon from "components/svg/PaginationDArrowRight.vue";
import PaginationArrowRightIcon from "components/svg/PaginationArrowRight.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import ClientOnly from "vue-client-only";
import LoadingIndicator from "components/LoadingIndicator.vue";
import { cityIn } from "lvovich";

export default {
  name: "CatalogPage",
  async asyncData({ store, route, link }) {
    store.state.alternateLink = link;
    const urlParams = new URLSearchParams(route.fullPath.replace("/catalog", ""));
    const params = {};
    if (urlParams.get("q")) {
      params.q = urlParams.get("q");
    }
    if (urlParams.getAll("type[]").length) {
      params.type = urlParams.getAll("type[]");
    }
    if (urlParams.get("page")) {
      params.page = urlParams.get("page");
    }
    if (urlParams.getAll("models[]").length) {
      params.models = urlParams.getAll("models[]");
    }
    await store.dispatch("GET_CATALOG_PAGE", params);
  },
  data() {
    return {
      loading: false,
      q: "",
      type: [],
      page: 1,
      models: [],
      settings: {
        wheelPropagation: true,
      },
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(decodeURI(location.search));
    if (urlParams.get("q")) {
      this.q = urlParams.get("q");
    }
    if (urlParams.getAll("type[]").length) {
      this.type = urlParams.getAll("type[]").map((t) => parseInt(t));
    }
    if (urlParams.get("page")) {
      this.page = urlParams.get("page");
    }
    if (urlParams.getAll("models[]").length) {
      this.models = urlParams.getAll("models[]").map((m) => parseInt(m));
    }
  },
  computed: {
    pageData() {
      let page;
      if (this.$store.state.catalog_page && this.$store.state.catalog_page.page) {
        page = this.$store.state.catalog_page.page;
      }
      return page;
    },
    brands() {
      if (this.$store.state.catalog_page && this.$store.state.catalog_page.brands) {
        return this.$store.state.catalog_page.brands;
      } else {
        return [];
      }
    },
    products() {
      if (this.$store.state.catalog_page && this.$store.state.catalog_page.products) {
        const products = this.$store.state.catalog_page.products;
        products.data = products.data.filter((p) => p.brand);
        return products;
      } else {
        return [];
      }
    },
    vehicles() {
      if (this.$store.state.catalog_page && this.$store.state.catalog_page.vehicles) {
        return this.$store.state.catalog_page.vehicles;
      } else {
        return [];
      }
    },
  },
  methods: {
    getImg(product) {
      if (product.head_img && product.head_img.img && product.head_img.img.url) {
        return this.$store.state.api + product.head_img.img.url;
      }
    },
    getImgAlt(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.alt) {
        return item.head_img.img.alt;
      }
    },
    async resetResults() {
      this.q = "";
      this.type = [];
      this.models = [];
      this.page = 1;
      this.getResults(this.page);
    },
    async getResults(page) {
      if (!this.loading) {
        this.loading = true;
        if (typeof page === "number") {
          this.page = page || 1;
        }
        this.$Progress.start();
        history.pushState({}, null, this.getPushStateLink());
        await this.$store.dispatch("GET_CATALOG_PAGE", {
          q: this.q,
          type: this.type,
          page: this.page,
          models: this.models,
        });
        this.loading = false;
        window.scrollTo(0, 0);
        this.$Progress.finish();
      }
    },
    /**
     * Метод сохраняет фильтры - создает строку с параметрами чтобы
     * сохранить в адресной строке и при перезагрузке сразу
     * применить фильтры
     */
    getPushStateLink() {
      let link = location.origin + location.pathname;

      /**
       * Форматирование
       * @param string - вот же все видно }:D
       * @returns {string}
       */
      function getParam(string) {
        return link.includes("?") ? "&" + string : "?" + string;
      }

      if (this.q) link += getParam("q=" + this.q);
      if (this.type.length) {
        this.type.forEach((m) => {
          link += getParam("type[]=" + m);
        });
      }
      if (this.models.length) {
        this.models.forEach((m) => {
          link += getParam("models[]=" + m);
        });
      }
      if (this.page) link += getParam("page=" + this.page);
      return link;
    },
  },

  metaInfo() {
    let api = this.$store.state.api;
    let alternateLink = this.$store.state.alternateLink;
    let data = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.catalog_page && this.$store.state.catalog_page.page) {
      data = this.$store.state.catalog_page.page;
    }

    let metaName = this.pageData.title;
    let metaFullPath = this.$route.fullPath;
    const title = (data.meta_title || data.title || "").replace(/{{ city }}/g, `в ${city}`);
    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: data.meta_description,
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: metaName,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: data.meta_keywords,
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: "",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    LoadingIndicator,
    PaginationArrowRightIcon,
    PaginationDArrowRightIcon,
    PaginationArrowLeftIcon,
    PaginationDArrowLeftIcon,
    QuestionIcon,
    ClientOnly,
    SuccessIcon,
    CloseIcon,
    ArrowIcon,
    BreadcrumbsComponent,
    PerfectScrollbar,
    Pagination: () => import("laravel-vue-pagination"),
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/car.styl"
@import "~@/styles/parts/perfect-scrollbar.styl"

.ctl {
  display flex
  flex-wrap wrap
  margin "-%s" % margin
  width "calc(100% + (%s * 2))" % margin

  &__aside {
    margin margin
    flex-basis "calc(25% - (%s * 2))" % margin
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    +below(1100px) {
      flex-basis "calc(33.333333% - (%s * 2))" % margin
    }
    +below(860px) {
      flex-basis "calc(41.6666667% - (%s * 2))" % margin
    }
    +below(640px) {
      flex-basis "calc(100% - (%s * 2))" % margin
    }

    &__actions {
      display flex
      flex-flow row wrap
      margin -5px

      & > * {
        margin 5px
      }
    }

    &__item {
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      margin-bottom 30px
      width 100%
      +below(520px) {
        &:not(:first-child) {
          display none
        }
      }
    }

    &__label {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: main_black;
      margin-bottom 10px
    }
  }

  &__body {
    margin margin
    flex-basis "calc(75% - (%s * 2))" % margin
    +below(1100px) {
      flex-basis "calc(66.6666667% - (%s * 2))" % margin
    }
    +below(860px) {
      flex-basis "calc(58.3333333% - (%s * 2))" % margin
    }
    +below(640px) {
      flex-basis "calc(100% - (%s * 2))" % margin
    }
  }

  &__active-filters {
    display flex
    flex-flow row wrap
    margin-bottom 50px

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: main_black;
      margin-right 10px
      display: flex;
      align-items: center;
    }

    &__list {
      display flex
      flex-flow row wrap
      list-style none
      margin -5px

      li {
        background: main_color;
        border-radius: 10px;
        display flex
        align-items center
        justify-content space-between
        margin 5px

        span {
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
          color: main_white;
          padding 5px 10px
        }

        button {
          background none
          border none
          cursor pointer
          height 35px
          width 35px
          display flex
          align-items center
          justify-content center

          .icon {
            width 10px
            height 10px
            display flex
            align-items center
            justify-content center

            svg path {
              fill main_white
            }
          }
        }
      }
    }
  }

  &__search {
    display flex
    align-items center
    justify-content flex-start
    position relative
    width 100%

    &__input {
      width 100%
      position relative
      z-index 1
    }

    &__btn {
      absolute right 15px top bottom
      background none
      border none
      cursor pointer

      svg {
        z-index 1

        path {
          fill main_black
        }
      }
    }
  }

  &__type-input {
    display flex
    align-items center
    justify-content flex-start
    position relative
    cursor pointer
    margin-bottom 5px

    &.active {
      .icon {
        background main_color
        border-color main_color

        svg {
          width 100%
          height 100%

          path[stroke] {
            stroke main_white
          }

          path[fill] {
            fill main_white
          }

          path:not([fill]):not([stroke]) {
            fill main_white
          }
        }
      }

      .ctl__type-input__title {
        color main_color
      }
    }

    &__field {
      visibility hidden
      opacity 0
      z-index -1
      absolute left 15px top bottom
    }

    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: main_dark;
    }

    .icon {
      width 60px
      height 60px
      display flex
      align-items center
      justify-content center
      padding 10px
      background: main_white;
      border: 1px solid main_gray;
      box-sizing: border-box;
      border-radius: 10px;
      margin-right 15px
      transition .3s

      svg {
        width 100%
        height 100%

        path[stroke] {
          transition .3s
          stroke main_dark
        }

        path[fill] {
          transition .3s
          fill main_dark
        }

        path:not([fill]):not([stroke]) {
          transition .3s
          fill main_dark
        }
      }
    }
  }

  &__models {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    position relative
    max-height: 150px;
    overflow hidden
    width 100%

    &__gradient {
      background: linear-gradient(180deg, rgba(main_white, 0) 0%, main_white 100%);
      height 30px
      width 100%
      z-index 3
      display flex
      flex-shrink 0
      position sticky
      bottom 0
    }

    &__label {
      display flex
      align-items center
      justify-content flex-start
      position relative
      cursor pointer
      margin-bottom 5px
      flex-shrink 0

      &.active {
        .ctl__models__title {
          color main_color

          &:after {
            background: main_color;
            border: 1px solid main_color;
          }
        }
      }
    }

    &__input {
      absolute left 5px top bottom
      visibility hidden
      opacity 0
    }

    &__title {
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      color: main_black;
      display flex
      align-items center
      transition .3s

      &:before {
        content: ""
        width 15px
        height 15px
        display block
        background main_white
        border 1px solid main_gray
        margin-right 10px
        position relative
        z-index 1
      }

      &:after {
        content: ""
        width 9px
        height 9px
        background transparent
        border: 1px solid transparent;
        box-sizing: border-box;
        absolute left 4px top bottom
        margin auto 0
        z-index 2
        transition .3s
      }
    }
  }

  &__items {
    list-style none
    display flex
    flex-flow row wrap
    margin "-%s" % margin

    li {
      flex-basis "calc(33.333333% - (%s * 2))" % margin
      margin margin
      +below(1100px) {
        flex-basis "calc(50% - (%s * 2))" % margin
      }
      +below(860px) {
        flex-basis "calc(100% - (%s * 2))" % margin
      }
      +below(640px) {
        flex-basis "calc(50% - (%s * 2))" % margin
      }
      +below(520px) {
        flex-basis "calc(100% - (%s * 2))" % margin
      }
    }
  }
}
</style>
